import { startCase } from "lodash";
import z from "zod";

const CAPITALIZED_REGEX = /^(?:[A-Z][a-z]*\s?)+$/;

export const stringOrNull = z.nullable(z.string());
export const numberOrNull = z.nullable(z.number());

export const capitalizedStringSchema = z.string().regex(CAPITALIZED_REGEX, {
  message: "all words in string must be capitalized",
});

export const dateFormatRFC3339 = z
  .string()
  .regex(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}[+-]\d{2}:\d{2}$/);

export function generateRSButtonTrackName({ id }: { id: string }) {
  return `${startCase(startCase(id).toLowerCase())} Button Clicked` as const;
}
