import {
  QUERY_PROGRESS_FAILED,
  QUERY_PROGRESS_SUCCEED,
  TRACK_EVENTS,
} from "core/consts";
import { isNewTheme } from "core/model/utils/featureFlags";
import { pathToUrl } from "core/model/utils/urls";
import { SEALD_TEST_URL_API, SEALD_TEST_URL_SSKS } from "core/seald";
import { QueryProgress } from "core/types";
import { Banner } from "ds/ui";
import { InfoBanner } from "ds_legacy/components/InfoBanner";
import LinkV2 from "ds_legacy/components/LinkV2";
import { Z_INDEX_MAX, sizing } from "ds_legacy/materials/metrics";
import { useEffect } from "react";
import { useTracking } from "react-tracking";
import { useTranslations } from "translations";
import { TranslationComposition } from "translations/helpers";

export const SealdWarningBanner = ({
  onSealdStatusChange,
  sealdStatus,
}: {
  onSealdStatusChange: (value: QueryProgress) => void;
  sealdStatus: QueryProgress;
}) => {
  const translations = useTranslations();
  const { trackEvent } = useTracking();

  const checkSealdStatus = async () => {
    try {
      await Promise.all([
        fetch(SEALD_TEST_URL_SSKS),
        fetch(SEALD_TEST_URL_API),
      ]);
      onSealdStatusChange(QUERY_PROGRESS_SUCCEED);
    } catch (_) {
      onSealdStatusChange(QUERY_PROGRESS_FAILED);
      trackEvent({ name: TRACK_EVENTS.SEALD_CONNECTION_BANNER });
    }
  };

  useEffect(() => {
    checkSealdStatus();
  }, []);

  if (sealdStatus !== QUERY_PROGRESS_FAILED) return null;

  return (
    <div
      style={{
        top: sizing(6.5),
        left: "50%",
        position: "fixed",
        transform: "translateX(-50%)",
        zIndex: Z_INDEX_MAX,
      }}
    >
      {isNewTheme() ? (
        <div className="mt-2 w-full">
          <Banner
            message={
              <TranslationComposition
                translations={
                  translations.general.banner.cta.noSealdConnectionAlert
                }
                withOptions
              >
                {([before, link, after]) => (
                  <>
                    {before}
                    <LinkV2
                      color="inherit"
                      underline="always"
                      href={pathToUrl({
                        pathname: "/diagnostic",
                        search: undefined,
                      })}
                    >
                      {link}
                    </LinkV2>
                    {after}
                  </>
                )}
              </TranslationComposition>
            }
            heading={
              translations.general.banner.cta.noEncryptionAccessAlertTitle
            }
            color="danger"
            actions={[
              {
                id: "refresh",
                label: translations.actions.refresh,
                onClick: () => {
                  checkSealdStatus();
                },
              },
            ]}
          />
        </div>
      ) : (
        <InfoBanner
          message={
            <TranslationComposition
              translations={
                translations.general.banner.cta.noSealdConnectionAlert
              }
              withOptions
            >
              {([before, link, after]) => (
                <>
                  {before}
                  <LinkV2
                    color="inherit"
                    underline="always"
                    href={pathToUrl({
                      pathname: "/diagnostic",
                      search: undefined,
                    })}
                  >
                    {link}
                  </LinkV2>
                  {after}
                </>
              )}
            </TranslationComposition>
          }
          header={translations.general.banner.cta.noEncryptionAccessAlertTitle}
          severity="error"
          wrapperStyle={{ width: "100%", marginTop: sizing(1) }}
          actions={[
            {
              label: translations.actions.refresh,
              onClick: () => {
                checkSealdStatus();
              },
            },
          ]}
        />
      )}
    </div>
  );
};
