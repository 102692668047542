import { QueuedToast, ToastState, ToastStateProps } from "@react-stately/toast";
import { NotificationContentType } from "ds/ui";
import { uniqueId } from "lodash";
import { Reducer, useCallback, useMemo, useReducer } from "react";

const NOTIFICATION_ACTIONS = {
  ADD: "ADD",
  CLOSE: "CLOSE",
};

type NotificationAction =
  | {
      payload: QueuedToast<NotificationContentType>;
      type: typeof NOTIFICATION_ACTIONS.ADD;
    }
  | {
      payload: QueuedToast<NotificationContentType>["key"];
      type: typeof NOTIFICATION_ACTIONS.CLOSE;
    };

const notificationReducer = (
  state: QueuedToast<NotificationContentType>[],
  action: NotificationAction,
): QueuedToast<NotificationContentType>[] => {
  switch (action.type) {
    case NOTIFICATION_ACTIONS.ADD: {
      return [action.payload as QueuedToast<NotificationContentType>, ...state];
    }
    case NOTIFICATION_ACTIONS.CLOSE: {
      return state.filter(
        (notification) => notification.key !== action.payload,
      );
    }
    default: {
      return state;
    }
  }
};

const notImplemeted = () => {};

export const useNotificationState = (
  props?: Pick<ToastStateProps, "maxVisibleToasts">,
): ToastState<NotificationContentType> => {
  const [notifications, dispatch] = useReducer<
    Reducer<QueuedToast<NotificationContentType>[], NotificationAction>
  >(notificationReducer, []);

  const visibleToasts = useMemo(
    () =>
      props?.maxVisibleToasts
        ? notifications.slice(0, props.maxVisibleToasts)
        : notifications,
    [props?.maxVisibleToasts, notifications],
  );

  const add = useCallback(
    (notification: NotificationContentType) => {
      const key = uniqueId("notification");
      dispatch({
        type: NOTIFICATION_ACTIONS.ADD,
        payload: { content: notification, key, animation: "entering" },
      });
      return key;
    },
    [dispatch],
  );

  const close = useCallback(
    (key: string) =>
      dispatch({
        type: NOTIFICATION_ACTIONS.CLOSE,
        payload: key,
      }),
    [dispatch],
  );

  return {
    visibleToasts,
    add,
    close,
    // required to meet react-aria ToastState
    remove: notImplemeted,
    pauseAll: notImplemeted,
    resumeAll: notImplemeted,
  };
};
