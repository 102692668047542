import ApolloClientProvider from "apollo/client";
import { WebSocketContextProvider } from "apollo/client/WebSocketContext";
import { EnvProvider } from "context/EnvContext";
import TrackingProvider from "context/TrackingProvider";
import { APP_ACP } from "core/consts";
import { isFrance, setApp } from "core/model/config";
import { useGetPlatformInfo } from "core/model/tracker/utils";
import { AppType } from "core/types";
import NetworkChecker from "ds_legacy/components/NetworkChecker";
import { SpinnerPage } from "ds_legacy/components/Spinner";
import { BrowserPermissionContextProvider } from "dsl/atoms/BrowserNotificationContext";
import { PrintContext } from "dsl/atoms/Contexts";
import HealthCheck from "dsl/atoms/HealthCheck";
import Intercom from "dsl/atoms/Intercom";
import { LegalDocumentsContextProvider } from "dsl/atoms/LegalDocuments";
import { TestEnvLoginProvider } from "dsl/molecules/TestEnvLogin";
import NotificationProvider from "dsl/organisms/NotificationProvider";
import VersionChecker from "dsl/organisms/VersionChecker";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { useGetLanguage } from "reduxentities/selectors/hooks";
import { AsyncTranslationsProvider } from "translations";
import { ToastNotificationProvider } from "../dsl/atoms/ToastNotificationContext";
import { A11yStatusProvider } from "./A11yStatusProvider/A11yStatusContext";
import ErrorBoundary from "./ErrorBoundary";
import MaintenanceBoundary from "./MaintenanceBoundary";
import SealdOnlyContextProvider from "./SealdOnlyProvider";
import { Services, ServicesProvider } from "./ServiceFactory";
import ThemeProvider, { NextUIThemeProvider } from "./ThemeProvider";
import WebSealdContextProvider from "./WebSealdContextProvider";

export default function ContextProvider({
  app,
  children,
  services,
}: {
  app: AppType;
  children: React.ReactNode;
  services: Services;
}) {
  setApp(app);
  const platformInfo = useGetPlatformInfo({ app });

  return (
    <Provider store={services.store}>
      <TrackingProvider app={app} platformInfo={platformInfo}>
        <EnvProvider app={app}>
          <ServicesProvider app={app}>
            <PrintContext.Provider
              value={location?.search?.includes("print") || false}
            >
              <A11yStatusProvider>
                <WebSocketContextProvider>
                  <ApolloClientProvider>
                    <PersistGate
                      loading={<SpinnerPage id="persist-gate" />}
                      persistor={services.persistor}
                    >
                      <HealthCheck>
                        <NextUIThemeProvider app={app}>
                          <ThemeProvider app={app}>
                            <AsyncTranslationsProvider
                              defaultLanguage={isFrance ? "fr" : undefined}
                              useGetLanguage={useGetLanguage}
                            >
                              <ErrorBoundary>
                                <MaintenanceBoundary>
                                  <TestEnvLoginProvider>
                                    <WebSealdContextProvider app={app}>
                                      <ToastNotificationProvider>
                                        <NotificationProvider>
                                          <LegalDocumentsContextProvider>
                                            <BrowserPermissionContextProvider>
                                              <SealdOnlyContextProvider>
                                                {children}
                                              </SealdOnlyContextProvider>
                                            </BrowserPermissionContextProvider>
                                          </LegalDocumentsContextProvider>
                                        </NotificationProvider>
                                      </ToastNotificationProvider>
                                      <VersionChecker />
                                      <NetworkChecker />
                                    </WebSealdContextProvider>
                                  </TestEnvLoginProvider>
                                </MaintenanceBoundary>
                              </ErrorBoundary>
                            </AsyncTranslationsProvider>
                            {app !== APP_ACP && <Intercom app={app} />}
                          </ThemeProvider>
                        </NextUIThemeProvider>
                      </HealthCheck>
                    </PersistGate>
                  </ApolloClientProvider>
                </WebSocketContextProvider>
              </A11yStatusProvider>
            </PrintContext.Provider>
          </ServicesProvider>
        </EnvProvider>
      </TrackingProvider>
    </Provider>
  );
}
