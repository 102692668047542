import { THEMES } from "../../core/consts";
import { commonColors as common } from "./common";
import { SemanticBaseColors, ThemeColors } from "./types";
import { generateForegroundColorObject } from "./utils/contrast";

const base: SemanticBaseColors = {
  light: {
    structure: {
      ...common.beige,
    },
    divider: {
      ...common.gray,
      DEFAULT: common.gray["800"],
    },
    focus: {
      ...common.blue,
      DEFAULT: common.blue[800],
    },
  },
};

const sharedThemeColors: Pick<
  ThemeColors,
  "success" | "warning" | "danger" | "default"
> = {
  default: {
    ...common.gray,
    DEFAULT: common.gray["700"],
    ...generateForegroundColorObject({
      DEFAULT: common.gray["700"],
    }),
    disabled: common.gray["600"],
  },
  success: {
    ...common.green,
    dark: common.green[800],
    DEFAULT: common.green[600],
    light: common.green[50],
    ...generateForegroundColorObject({
      dark: common.green[800],
      DEFAULT: common.green[600],
      light: common.green[50],
    }),
  },
  warning: {
    ...common.orange,
    light: common.orange[50],
    DEFAULT: common.orange[400],
    dark: common.orange[700],
    ...generateForegroundColorObject({
      light: common.orange[50],
      DEFAULT: common.orange[400],
      dark: common.orange[700],
    }),
  },
  danger: {
    ...common.red,
    light: common.red[100],
    DEFAULT: common.red[700],
    dark: common.red[800],
    ...generateForegroundColorObject({
      light: common.red[100],
      DEFAULT: common.red[700],
      dark: common.red[800],
    }),
  },
};

const themeRecare: DeepPartial<ThemeColors> = {
  ...base.light,
  ...sharedThemeColors,
  primary: {
    ...common.blue,
    light: common.blue[50],
    DEFAULT: common.blue[500],
    dark: common.blue[800],
    ...generateForegroundColorObject({
      light: common.blue[50],
      DEFAULT: common.blue[500],
      dark: common.blue[800],
    }),
  },
  secondary: {
    ...common.yellow,
    light: common.yellow[100],
    DEFAULT: common.yellow[200],
    dark: common.yellow[300],
    ...generateForegroundColorObject({
      light: common.yellow[100],
      DEFAULT: common.yellow[200],
      dark: common.yellow[300],
    }),
  },
  accent: {
    ...common.purple,
    light: common.purple[200],
    DEFAULT: common.purple[300],
    dark: common.purple[600],
    ...generateForegroundColorObject({
      light: common.purple[200],
      DEFAULT: common.purple[300],
      dark: common.purple[600],
    }),
  },
};

const themeBCP: DeepPartial<ThemeColors> = {
  ...base.light,
  ...sharedThemeColors,
  primary: {
    ...common.magenta,
    light: common.magenta[300],
    DEFAULT: common.magenta[500],
    dark: common.magenta[800],
    ...generateForegroundColorObject({
      light: common.magenta[300],
      DEFAULT: common.magenta[500],
      dark: common.magenta[800],
    }),
  },
  accent: {
    ...common.bcpAccent,
    light: common.bcpAccent[50],
    dark: common.bcpAccent[900],
    ...generateForegroundColorObject({
      light: common.bcpAccent[50],
      dark: common.bcpAccent[900],
    }),
  },
  ...sharedThemeColors,
};

const themeWeserEms: DeepPartial<ThemeColors> = {
  ...base.light,
  ...sharedThemeColors,
  primary: {
    ...common.steelBlue,
    light: common.steelBlue[200],
    DEFAULT: common.steelBlue[600],
    dark: common.steelBlue[800],
    ...generateForegroundColorObject({
      light: common.steelBlue[200],
      DEFAULT: common.steelBlue[600],
      dark: common.steelBlue[800],
    }),
  },
  ...sharedThemeColors,
};

export const semanticColors = {
  [THEMES.WESER_EMS]: themeWeserEms,
  [THEMES.PROVIDER_SEARCH]: themeBCP,
  [THEMES.RECARE]: themeRecare,
};
