import { AriaToastRegionProps, useToastRegion } from "@react-aria/toast";
import { ToastState } from "@react-stately/toast";
import {
  Notification,
  NotificationContentType,
  Toast,
  ToastContentType,
} from "ds/ui";
import { useRef } from "react";

type ToastNotificationRegionProps = AriaToastRegionProps & {
  notificationState: ToastState<NotificationContentType>;
  toastState: ToastState<ToastContentType>;
};

export function ToastNotificationRegion({
  notificationState,
  toastState,
  ...props
}: ToastNotificationRegionProps) {
  const ref = useRef(null);

  const { regionProps: notificationRegionProps } =
    useToastRegion<NotificationContentType>(props, notificationState, ref);

  const { regionProps: toastRegionProps } = useToastRegion<ToastContentType>(
    props,
    toastState,
    ref,
  );

  return (
    <div ref={ref}>
      <div
        {...toastRegionProps}
        className="fixed bottom-0 left-1/2 z-toast flex -translate-x-1/2 transform flex-col gap-2 p-4"
      >
        {toastState.visibleToasts.map((toast) => {
          return <Toast key={toast.key} toast={toast} state={toastState} />;
        })}
      </div>
      <div
        {...notificationRegionProps}
        className="fixed right-0 top-0 z-toast flex transform flex-col gap-2 p-4"
      >
        {notificationState.visibleToasts.map((toast) => {
          return (
            <Notification
              key={toast.key}
              toast={toast}
              state={notificationState}
            />
          );
        })}
      </div>
    </div>
  );
}
