import {
  Input as NextUIInput,
  InputProps as NextUIInputProps,
} from "@nextui-org/react";
import clsx from "clsx";
import { forwardRef } from "react";

type Color = Extract<NextUIInputProps["color"], "default">;
type LabelPlacement = Extract<NextUIInputProps["labelPlacement"], "outside">;
type Variant = Extract<NextUIInputProps["variant"], "bordered">;

export type InputProps = Omit<
  NextUIInputProps,
  "color" | "labelPlacement" | "variant"
> & {
  color?: Color;
  labelPlacement?: LabelPlacement;
  variant?: Variant;
};

export const Input = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  return (
    <NextUIInput
      ref={ref}
      {...props}
      classNames={{
        base: ["opacity-100"],
        inputWrapper: [
          clsx({
            "px-3": props.size === "sm",
            "px-4": props.size === "md" || props.size === "lg",
          }),
          "bg-white rounded-lg ring-1 ring-inset ring-gray-500 border-0 data-[disabled=true]:ring-gray-300",
          "data-[hover=true]:ring-gray-600",
          "group-data-[focus=true]:ring-primary group-data-[focus=true]:ring-2",
          "data-[invalid=true]:ring-danger group-data-[invalid=true]:text-danger",
          "group-data-[disabled=true]:bg-gray-200 group-data-[disabled=true]:text-gray-disabled",
        ],
        input: [
          "placeholder:text-gray-600",
          "data-[has-start-content=true]:ps-2 data-[has-end-content=true]:pe-2",
          "group-data-[invalid=true]:placeholder:text-danger",
        ],
        label: [
          clsx({
            "start-3 text-sm": props.size === "sm",
            "start-4": props.size === "md" || props.size === "lg",
          }),
          "!text-default-900 font-semibold",
        ],
        innerWrapper: [
          clsx({
            "[&>svg]:w-4": props.size === "sm",
            "[&>svg]:w-5": props.size === "md",
            "[&>svg]:w-6": props.size === "lg",
          }),
          "[&>svg]:!stroke-sm",
        ],
      }}
      color="default"
      data-testid={props.name}
      labelPlacement="outside"
      variant="bordered"
    />
  );
});

Input.displayName = "RecareUI.Input";
