import {
  Progress as NextUIProgress,
  ProgressProps as NextUIProgressProps,
} from "@nextui-org/react";
import usePasswordStrength, {
  MAX,
  MEDIUM,
  STRONG,
  VERY_STRONG,
  VERY_WEAK,
  WEAK,
} from "dsl/hooks/usePasswordStrength";
import { forwardRef, useMemo } from "react";
import { useTranslations } from "translations";

type Color = Extract<
  NextUIProgressProps["color"],
  "success" | "warning" | "danger"
>;

export type PasswordIndicatorProps = Omit<NextUIProgressProps, "color"> & {
  password: string;
};

type PasswordStrength = {
  color: Color;
  valueLabel: string;
};

export const PasswordIndicator = forwardRef<
  HTMLDivElement,
  PasswordIndicatorProps
>((props, ref) => {
  const translations = useTranslations();
  const { strength } = usePasswordStrength(props.password);
  const { color, valueLabel }: PasswordStrength = useMemo(() => {
    switch (strength) {
      case VERY_STRONG:
        return {
          valueLabel: translations.login.loginPage.passwordVeryStrong,
          color: "success",
        };
      case STRONG:
        return {
          valueLabel: translations.login.loginPage.passwordStrong,
          color: "success",
        };
      case MEDIUM:
        return {
          valueLabel: translations.login.loginPage.passwordMedium,
          color: "warning",
        };
      case WEAK:
        return {
          valueLabel: translations.login.loginPage.passwordWeak,
          color: "danger",
        };
      case VERY_WEAK:
      default:
        return {
          valueLabel: translations.login.loginPage.passwordVeryWeak,
          color: "danger",
        };
    }
  }, [strength, translations]);

  return (
    <NextUIProgress
      ref={ref}
      {...props}
      size="sm"
      color={color}
      value={strength}
      maxValue={MAX}
      aria-label={translations.login.loginPage.passwordIndicatorAriaLabel}
      valueLabel={valueLabel}
      showValueLabel={true}
      classNames={{
        base: "gap-1",
        value: "text-xs",
        label: "text-large",
        labelWrapper: "order-1 self-end",
        track: "bg-gray-400",
      }}
    />
  );
});

PasswordIndicator.displayName = "RecareUI.PasswordIndicator";
