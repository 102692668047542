import {
  ACCEPT_BUTTON_CLICKED,
  APP_VERSION_NAMES,
  BACK_BUTTON_CLICKED,
  DECLINE_BUTTON_CLICKED,
  MESSENGER_TAB_MESSENGER,
  REJECT_BUTTON_CLICKED,
  SEND_REQUEST_BUTTON_CLICKED,
  TRACK_EVENTS,
  VALIDATE_BUTTON_CLICKED,
} from "core/consts";
import {
  ENV_DEMO,
  ENV_DEVELOPMENT,
  ENV_PREPROD,
  ENV_PRODUCTION,
  ENV_STAGING,
} from "core/model/config";
import * as z from "zod";
import { isTitleCase } from "../../model/utils/strings";
import {
  capitalizedStringSchema,
  dateFormatRFC3339,
  generateRSButtonTrackName,
  numberOrNull,
  stringOrNull,
} from "../utils";

/** * * * * BASE EVENT SCHEMA * * * * * * * * * * * * * * * * * * * * * * * *
 * The base event schema is the foundation for all events submitted to BI.
 * It contains the core properties that every event must have.
 * This schema serves as a baseline for all events and is used to validate
 * the basic structure of an event before it is processed further.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
export type BaseEventSchema = z.infer<typeof baseEventSchema>;
export type PlatformInfo = z.infer<typeof platform_information>;
export type TrackingEventName =
  | ReturnType<typeof generateRSButtonTrackName>
  | z.infer<typeof trackingEventName>;

const trackingEventName = z.nativeEnum(TRACK_EVENTS);
const rsButtonEventName = z.string().superRefine((value, ctx) => {
  const id = value.slice(0, value.lastIndexOf(" Button Clicked"));

  if (!isTitleCase(value)) {
    ctx.addIssue({
      code: z.ZodIssueCode.invalid_type,
      expected: "string",
      message: `Invalid casing. Expected Title Case. Received ${value}`,
      path: ["id"],
      received: typeof value,
    });
  }
  if (value !== generateRSButtonTrackName({ id })) {
    ctx.addIssue({
      code: z.ZodIssueCode.invalid_type,
      expected: "string",
      message: `Invalid name format. Expected "[regex] Button Clicked". Received ${value}`,
      path: ["id"],
      received: typeof value,
    });
  }
});

const trackingEventNameExtended = z.custom((value) => {
  if (Object.values(TRACK_EVENTS).includes(value as any)) {
    return trackingEventName.parse(value);
  } else {
    return rsButtonEventName.parse(value);
  }
});

const env = z.union([
  z.literal(ENV_DEMO),
  z.literal(ENV_DEVELOPMENT),
  z.literal(ENV_PREPROD),
  z.literal(ENV_PRODUCTION),
  z.literal(ENV_STAGING),
  z.null(),
]);

const user_information = z.object({
  account_id: numberOrNull,
  auction_id: numberOrNull,
  auction_request_id: numberOrNull,
  careprovider_id: numberOrNull,
  careprovider_name: stringOrNull,
  careseeker_id: numberOrNull,
  careseeker_name: stringOrNull,
  first_name: stringOrNull,
  last_name: stringOrNull,
  patient_id: numberOrNull,
});

const platform_information = z.object({
  application: z.nativeEnum(APP_VERSION_NAMES),
  browser_version: stringOrNull,
  browser: stringOrNull,
  build: stringOrNull,
  device_id: stringOrNull,
  device_screen_height: numberOrNull,
  device_screen_width: numberOrNull,
  env,
  hostname: stringOrNull,
  is_pwa: z.boolean(),
  model: stringOrNull,
  operation_system_version: stringOrNull,
  operation_system: stringOrNull,
  platform: stringOrNull,
  version: stringOrNull,
});

export const baseEventSchema = z.object({
  name: trackingEventNameExtended,
  platform_information,
  page_information: z
    .object({
      duration_in_seconds: z.number().optional(),
      page_name: capitalizedStringSchema,
      path: z.string(),
      pathname: z.string(),
      previous_page: z.string(),
      referrer: z.string(),
      total_duration_in_seconds: z.number().optional(),
    })
    .optional(),
  session_information: z.object({
    session_id: z.string(),
    session_item: z.number(),
  }),
  timestamp: dateFormatRFC3339,
  user_information,
  system_event: z.boolean().optional(),
});

/** * * * BASE EVENT SCHEMA (Sender VARIANT) * * * * * * * * * * * * * * * * * *
 * A variation of the base event schema has been created specifically
 * for events generated by the sender app.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const baseEventSchemaSender = baseEventSchema
  .strict()
  .superRefine((data, ctx) => {
    if (data.user_information.careprovider_id !== null) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        expected: "null",
        message: "careprovider_id must be null in the careseeker app",
        path: ["user_information", "careprovider_id"],
        received: typeof data.user_information.careprovider_id,
      });
    }
  });

/** * * * BASE EVENT SCHEMA (Careprovider VARIANT) * * * * * * * * * * * * * * * * * *
 * A variation of the base event schema has been created specifically
 * for events generated by the careprovider app.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

export const baseEventSchemaCareprovider = baseEventSchema
  .strict()
  .superRefine((data, ctx) => {
    if (data.user_information.careseeker_id !== null) {
      ctx.addIssue({
        code: z.ZodIssueCode.invalid_type,
        expected: "null",
        message: "careseeker_id must be null in the careprovider app",
        path: ["user_information", "careseeker_id"],
        received: typeof data.user_information.careseeker_id,
      });
    }
  });

/** * * * BASE EVENT SCHEMA (B2C VARIANT) * * * * * * * * * * * * * * * * * *
 * A variation of the base event schema has been created specifically
 * for events generated by the B2C app.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */
const B2C_user_information = user_information.omit({ account_id: true });
export const baseEventSchemaB2C = baseEventSchema
  .omit({ user_information: true })
  .extend({
    account_id: numberOrNull.optional(),
    user_information: B2C_user_information,
  })
  .strict();

/** * * * CUSTOM EVENT SCHEMAS * * * * * * * * * * * * * * * * * * * * * * *
 * In addition to the base event schema, custom event schemas can be defined for specific events.
 * These schemas are used for validation of events that require
 * specific properties in addition to the base properties.
 * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

const customBaseEventSchema = z.object({
  name: trackingEventNameExtended,
});

export type UserManagementUpdateAccountSchema = z.infer<
  typeof userManagementUpdateAccountSchema
>;
export const userManagementUpdateAccountSchema = customBaseEventSchema
  .extend({
    name: z.union([
      z.literal(TRACK_EVENTS.UM_DEACTIVATE_CLICKED),
      z.literal(TRACK_EVENTS.UM_DEACTIVATE_ERROR),
      z.literal(TRACK_EVENTS.UM_DEACTIVATE_MODAL_CANCELLED),
      z.literal(TRACK_EVENTS.UM_DEACTIVATE_MODAL_CONFIRMED),
      z.literal(TRACK_EVENTS.UM_DEACTIVATE_SUCCESS),
      z.literal(TRACK_EVENTS.UM_EDIT_CLICKED),
      z.literal(TRACK_EVENTS.UM_EDIT_ERROR),
      z.literal(TRACK_EVENTS.UM_EDIT_MODAL_CANCELLED),
      z.literal(TRACK_EVENTS.UM_EDIT_MODAL_CONFIRMED),
      z.literal(TRACK_EVENTS.UM_EDIT_SUCCESS),
      z.literal(TRACK_EVENTS.UM_REACTIVATE_CLICKED),
      z.literal(TRACK_EVENTS.UM_REACTIVATE_ERROR),
      z.literal(TRACK_EVENTS.UM_REACTIVATE_MODAL_CANCELLED),
      z.literal(TRACK_EVENTS.UM_REACTIVATE_MODAL_CONFIRMED),
      z.literal(TRACK_EVENTS.UM_REACTIVATE_SUCCESS),
      z.literal(TRACK_EVENTS.UM_RESEND_ACTIVATION_EMAIL_CLICKED),
      z.literal(TRACK_EVENTS.UM_RESEND_ACTIVATION_EMAIL_ERROR),
      z.literal(TRACK_EVENTS.UM_RESEND_ACTIVATION_EMAIL_MODAL_CANCELLED),
      z.literal(TRACK_EVENTS.UM_RESEND_ACTIVATION_EMAIL_MODAL_CONFIRMED),
      z.literal(TRACK_EVENTS.UM_RESEND_ACTIVATION_EMAIL_SUCCESS),
      z.literal(TRACK_EVENTS.UM_RESET_PASSWORD_EMAIL_CLICKED),
      z.literal(TRACK_EVENTS.UM_RESET_PASSWORD_EMAIL_ERROR),
      z.literal(TRACK_EVENTS.UM_RESET_PASSWORD_EMAIL_MODAL_CANCELLED),
      z.literal(TRACK_EVENTS.UM_RESET_PASSWORD_EMAIL_MODAL_CONFIRMED),
      z.literal(TRACK_EVENTS.UM_RESET_PASSWORD_EMAIL_SUCCESS),
    ]),
    edited_account_id: z.number(),
  })
  .strict();

export type PrintButtonConfirmedSchema = z.infer<
  typeof printButtonConfirmedSchema
>;
export const printButtonConfirmedSchema = customBaseEventSchema
  .extend({
    print_chat_history: z.boolean(),
    print_profile_information: z.boolean(),
  })
  .strict();

export type RequestActionButtonSchema = z.infer<
  typeof requestActionButtonSchema
>;
export const requestActionButtonSchema = z
  .object({
    name: z.literal(TRACK_EVENTS.REQUEST_ACTION),
    action: z.union([
      z.literal(ACCEPT_BUTTON_CLICKED),
      z.literal(DECLINE_BUTTON_CLICKED),
      z.literal(REJECT_BUTTON_CLICKED),
      z.literal(SEND_REQUEST_BUTTON_CLICKED),
      z.literal(VALIDATE_BUTTON_CLICKED),
    ]),
  })
  .strict();

export type RequestPanelSchema = z.infer<typeof requestPanelSchema>;
export const requestPanelSchema = z
  .object({
    name: z.union([
      z.literal(TRACK_EVENTS.REQUEST_PANEL_ACCEPT),
      z.literal(TRACK_EVENTS.REQUEST_PANEL_DECLINE),
      z.literal(TRACK_EVENTS.REQUEST_PANEL_REJECT),
      z.literal(TRACK_EVENTS.REQUEST_PANEL_SEND_REQUEST),
      z.literal(TRACK_EVENTS.REQUEST_PANEL_VALIDATE),
    ]),
    action: z.union([
      z.literal(ACCEPT_BUTTON_CLICKED),
      z.literal(BACK_BUTTON_CLICKED),
      z.literal(DECLINE_BUTTON_CLICKED),
      z.literal(REJECT_BUTTON_CLICKED),
      z.literal(SEND_REQUEST_BUTTON_CLICKED),
      z.literal(VALIDATE_BUTTON_CLICKED),
    ]),
  })
  .strict();

export const messengerSendMessageSchema = z.object({
  name: z.literal(TRACK_EVENTS.MESSENGER_SEND_MESSAGE_BUTTON),
  tab: z.literal(MESSENGER_TAB_MESSENGER),
  auction_request_status: z.number(),
});

export type EditPatientButtonSchema = z.infer<typeof editPatientButtonSchema>;

export const editPatientButtonSchema = z.object({
  name: z.literal(TRACK_EVENTS.EDIT_PATIENT_BUTTON_CLICKED),
  auction_status: z.string(),
});

export type ContactReceiverButtonSchema = z.infer<
  typeof contactReceiverButtonSchema
>;

export const contactReceiverButtonSchema = z.object({
  name: z.literal(TRACK_EVENTS.CONTACT_RECEIVER_BUTTON_CLICKED),
  auction_status: z.string(),
});

export type TableSortingButtonSchema = z.infer<typeof tableSortingButtonSchema>;

export const tableSortingButtonSchema = z.object({
  name: z.union([
    z.literal(TRACK_EVENTS.TABLE_SORT_BY_DEFAULT_CLICKED),
    z.literal(TRACK_EVENTS.TABLE_SORT_BY_DISTANCE_CLICKED),
    z.literal(TRACK_EVENTS.TABLE_SORT_BY_NAME_CLICKED),
  ]),
  table: z.string(),
});

export type SearchReceiversButtonSchema = z.infer<
  typeof searchReceiversButtonSchema
>;

export const searchReceiversButtonSchema = z.object({
  name: z.literal(TRACK_EVENTS.SEARCH_PROVIDER_BUTTON_CLICKED),
  auction_status: z.string(),
});

export const contactAnywayConfirmButtonSchema = z.object({
  name: z.literal(TRACK_EVENTS.CONTACT_ANYWAY_MODAL_CONFIRM_BUTTON_CLICKED),
  careprovider_id: z.number(),
});
